/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from "react";
import { useGLTF, useTexture, Html } from "@react-three/drei";
import * as THREE from "three";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/ChaussureDemo.glb");

  const ref = useRef();

  /* LOAD TEXTURE */
  const repeatX = props.scale;
  const repeatY = props.scale;

  const [base, normal, metal, rough] = useTexture([
    `/textures/${
      props.texture >= 100 ? props.texture : "0" + props.texture
    }/T_${
      props.texture >= 100 ? props.texture : "0" + props.texture
    }_Color.jpg`,
    `/textures/${
      props.texture >= 100 ? props.texture : "0" + props.texture
    }/T_${
      props.texture >= 100 ? props.texture : "0" + props.texture
    }_Normal-fs8.png`,
    `/textures/${
      props.texture >= 100 ? props.texture : "0" + props.texture
    }/T_${
      props.texture >= 100 ? props.texture : "0" + props.texture
    }_Metallic-fs8.png`,
    `/textures/${
      props.texture >= 100 ? props.texture : "0" + props.texture
    }/T_${
      props.texture >= 100 ? props.texture : "0" + props.texture
    }_Roughness-fs8.png`
  ]);
  [base, normal, metal, rough].forEach((texture) => {
    texture.wrapS = THREE.MirroredRepeatWrapping;
    texture.wrapT = THREE.MirroredRepeatWrapping;
    texture.repeat.set(repeatX, repeatY);
  });

  /* END LOAD TEXTURE */

  return (
    <>
      <group ref={group} {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Lanière.geometry}
          material={nodes.Lanière.material}
        >
          <meshStandardMaterial
            attach="material"
            map={base}
            normalMap={normal}
            roughnessMap={rough}
            metalnessMap={metal}
            color={base}
            needsUpdate
            opacity={1}
            emissive
            aoMapIntensity={1}
            alphaTest={0}
            metalness={1}
            roughness={1}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Boucle-metal"].geometry}
          material={nodes["Boucle-metal"].material}
       />
       
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Semelle-extérieur"].geometry}
          material={nodes["Semelle-extérieur"].material}
       />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Talon.geometry}
          material={nodes.Talon.material}
        >
          <meshStandardMaterial
            attach="material"
            map={base}
            normalMap={normal}
            roughnessMap={rough}
            metalnessMap={metal}
            color={base}
            needsUpdate
            opacity={1}
            emissive
            aoMapIntensity={1}
            alphaTest={0}
            metalness={1}
            roughness={1}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Pointe-talon"].geometry}
          material={nodes["Pointe-talon"].material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Coque_Arriere.geometry}
          material={nodes.Coque_Arriere.material}
        >
          <meshStandardMaterial
            attach="material"
            map={base}
            normalMap={normal}
            roughnessMap={rough}
            metalnessMap={metal}
            color={base}
            needsUpdate
            opacity={1}
            emissive
            aoMapIntensity={1}
            alphaTest={0}
            metalness={1}
            roughness={1}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Coque_Avant.geometry}
          material={nodes.Coque_Avant.material}
        >
          <meshStandardMaterial
            attach="material"
            map={base}
            normalMap={normal}
            roughnessMap={rough}
            metalnessMap={metal}
            color={base}
            needsUpdate
            opacity={1}
            emissive
            aoMapIntensity={1}
            alphaTest={0}
            metalness={1}
            roughness={1}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Semelle-int"].geometry}
          material={nodes["Semelle-int"].material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Contour_Arriere.geometry}
          material={nodes.Contour_Arriere.material}
        >
          <meshStandardMaterial
            attach="material"
            map={base}
            normalMap={normal}
            roughnessMap={rough}
            metalnessMap={metal}
            color={base}
            needsUpdate
            opacity={1}
            emissive
            aoMapIntensity={1}
            alphaTest={0}
            metalness={1}
            roughness={1}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Tige-metal"].geometry}
          material={nodes["Tige-metal"].material}
        >
          <meshStandardMaterial
            attach="material"
            map={base}
            normalMap={normal}
            roughnessMap={rough}
            metalnessMap={metal}
            color={base}
            needsUpdate
            opacity={1}
            emissive
            aoMapIntensity={1}
            alphaTest={0}
            metalness={1}
            roughness={1}
          />
        </mesh>
      </group>
    </>
  );
}

useGLTF.preload("/ChaussureDemo.glb");
