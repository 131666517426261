import React, { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stage, Loader } from "@react-three/drei";
import Model from "../Model";
import { useControls, folder } from "leva";

const CanvasShoes = (props) => {
  const ref = useRef();

 const modelEnv = {
    where: {
      sunset: "sunset",
      apartment: "apartment",
      dawn: "dawn",
      night: "night",
      warehouse: "warehouse",
      forest: "forest",
      studio : "studio",
      park: "park",
      lobby: "lobby"
    },
  };
  const configList = modelEnv.where;

  const modelEnvPreset = {
    where: {
      portrait: "portrait",
      upfront: "upfront",
      soft: "soft",
    },
  };
  const configPreset = modelEnvPreset.where;

  const {
    intensity,
    modelEnvironment,
    modelPreset,
  } = useControls({
    intensity: folder({
      intensity: { value: 0.1, min: 0.1, max: 1, step: 0.05 },
    }),
    environnement: folder({
      modelEnvironment: { value: "warehouse", options: configList },
    }),
    preset: folder({
      modelPreset: { value: "portrait", options: configPreset },
    }),
  });



  return (
    <>
      <Canvas shadows dpr={[1, 2]} camera={{ fov: 50 }}>
        <Suspense fallback={null}>
          <Stage
            controls={ref}
            preset={modelPreset}
            intensity={intensity}
            environment={modelEnvironment}
          >
            <Model texture={props.texture} scale={props.scale} />
          </Stage>
        </Suspense>
        <OrbitControls ref={ref} /* autoRotate */ />
      </Canvas>
{/*       <Loader />
 */}    </>
  );
};

export default CanvasShoes;
