import React, { Suspense, useRef, useState } from "react";
import {
  Row,
  Container,
  Col,
  Button,
  Image,
  Tab,
  Tabs,
  Alert,
} from "react-bootstrap";
import CanvasShoes from "./components/canvasShoes";

export default function Viewer() {
  const [texture, setTexture] = useState(47);
  const [scale, setScale] = useState(5);

  function handleTexture(params1, params2) {
    setTexture(params1);
    setScale(params2);
  }
  return (
    <>
      <Container>
        <Row className="">
          <Col md={6}>
            <CanvasShoes texture={texture} scale={scale} />
          </Col>
          <Col md={6} className="m-auto">
            <Alert variant={"primary"}>
              Texture n° {texture} , Scale {scale}
            </Alert>
            <div className="d-grid gap-2"></div>
            <Tabs
              defaultActiveKey="fantaisie"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="fantaisie" title="Fantaisie">
                <Row>
                  <Col md={4}>
                    <span onClick={() => handleTexture("20", 2)}>
                      <Image
                        src="textures/020/020.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Light Leopard leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("38", 1)}>
                      <Image
                        src="textures/038/038.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Red fantasy Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("50", 3)}>
                      <Image
                        src="textures/050/050.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Snake Gold Leather</span>
                    </span>
                  </Col>
          
                  <Col md={4}>
                    <span onClick={() => handleTexture("53", 3)}>
                      <Image
                        src="textures/053/053.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Snake Silver Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("55", 1)}>
                      <Image
                        src="textures/055/055.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Snake Blue Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("61", 4)}>
                      <Image
                        src="textures/061/061.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Black Diva Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("67", 4)}>
                      <Image
                        src="textures/067/067.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Tan Diva Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("71", 1)}>
                      <Image
                        src="textures/071/071.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">
                        Mix Silver Fantasy Leather
                      </span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("93", 3)}>
                      <Image
                        src="textures/093/093.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Snake bege Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("135", 1)}>
                      <Image
                        src="textures/135/135.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Glitter Red</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("137", 1)}>
                      <Image
                        src="textures/137/137.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Glitter platinium</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("138", 1)}>
                      <Image
                        src="textures/138/138.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Glitter Tan</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("139", 1)}>
                      <Image
                        src="textures/139/139.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Glitter Silver</span>
                    </span>
                  </Col>
                  {/* add */}
                  <Col md={4}>
                    <span onClick={() => handleTexture("169", 10)}>
                      <Image
                        src="textures/169/169.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Silver Cuarzo Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("171", 9)}>
                      <Image
                        src="textures/171/171.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Dark Black Snake</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("178", 9)}>
                      <Image
                        src="textures/178/178.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Dark Red Snake</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("231", 1)}>
                      <Image
                        src="textures/231/231.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Gal Black Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("238", 1)}>
                      <Image
                        src="textures/238/238.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">GalRed</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("GT9", 2)}>
                      <Image
                        src="textures/0GT9/GT9.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">GlitterLux Silver</span>
                    </span>
                  </Col>
                  {/* NEW */}
                  <Col md={4}>
                    <span onClick={() => handleTexture("69", 4.2)}>
                      <Image
                        src="textures/069/069.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">SilverDiva</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("73", 0.5)}>
                      <Image
                        src="textures/073/073.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">GreyFlower</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("92", 5)}>
                      <Image
                        src="textures/092/092.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">SnakePink</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("112", 3.5)}>
                      <Image
                        src="textures/112/112.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">BlueNerhu</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("145", 1)}>
                      <Image
                        src="textures/145/145.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">TautoLeather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("168", 2)}>
                      <Image
                        src="textures/168/168.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">TanCuarzoLeather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("232", 1)}>
                      <Image
                        src="textures/232/232.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">GalLilac</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("233", 1)}>
                      <Image
                        src="textures/233/233.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">GalGreen</span>
                    </span>
                  </Col>
                  {/* END NEW */}
                  {/* other */}

                  <Col md={4}>
                    <span onClick={() => handleTexture("84", 0.5)}>
                      <Image
                        src="textures/084/084.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">WhiteFlowerLeather</span>
                    </span>
                  </Col>
{/*                   <Col md={4}>
                    <span onClick={() => handleTexture("113", 3.5)}>
                      <Image
                        src="textures/113/113.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">NerhuLeather</span>
                    </span>
                  </Col> */}
                  <Col md={4}>
                    <span onClick={() => handleTexture("262", 2)}>
                      <Image
                        src="textures/262/262.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">SilverPinkLeather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("280", 5)}>
                      <Image
                        src="textures/280/280.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">MirrorLeather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("294", 3)}>
                      <Image
                        src="textures/294/294.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">MultiGreen</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("313", 5)}>
                      <Image
                        src="textures/313/313.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">MaitGreen</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("548", 5)}>
                      <Image
                        src="textures/548/548.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">DarkOrangeShiny</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("GS4", 2)}>
                      <Image
                        src="textures/0GS4/0GS4.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">GS4</span>
                    </span>
                  </Col>
                  {/* end Other */}
                </Row>
              </Tab>
              <Tab eventKey="brillants" title="Brillants">
                <Row>
                  <Col md={4}>
                    <span onClick={() => handleTexture("147", 10)}>
                      <Image
                        src="textures/147/147.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Platinium Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("148", 10)}>
                      <Image
                        src="textures/148/148.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Bronze Metal Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("329", 10)}>
                      <Image
                        src="textures/329/329.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Pas de nom</span>
                    </span>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="matte" title="Mattes">
                <Row>
                  <Col md={4}>
                    <span onClick={() => handleTexture("01", 5)}>
                      <Image
                        src="textures/001/001.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Black Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("04", 5)}>
                      <Image
                        src="textures/004/004.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">White Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("06", 5)}>
                      <Image
                        src="textures/006/006.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Navy Blue Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("07", 5)}>
                      <Image
                        src="textures/007/007.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Tan Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("246", 5)}>
                      <Image
                        src="textures/246/246.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Bege Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("320", 5)}>
                      <Image
                        src="textures/320/320.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Pas de nom</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("323", 5)}>
                      <Image
                        src="textures/323/323.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Pas de nom</span>
                    </span>
                  </Col>
                                    <Col md={4}>
                    <span onClick={() => handleTexture("243", 5)}>
                      <Image
                        src="textures/243/243.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Pale Green</span>
                    </span>
                  </Col>
                  
                </Row>
              </Tab>
              <Tab eventKey="vernis" title="Vernis">
                <Row>
                  <Col md={4}>
                    <span onClick={() => handleTexture("08", 1)}>
                      <Image
                        src="textures/008/008.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Gold Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("09", 1)}>
                      <Image
                        src="textures/009/009.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Silver Leather</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("32", 1)}>
                      <Image
                        src="textures/032/032.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Pink Vernish</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("33", 1)}>
                      <Image
                        src="textures/033/033.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Pas de nom</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("34", 1)}>
                      <Image
                        src="textures/034/034.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">White Vernish</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("35", 1)}>
                      <Image
                        src="textures/035/035.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Black Vernish</span>
                    </span>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="velours" title="Velours">
                <Row>
                  <Col md={4}>
                    <span onClick={() => handleTexture("22", 3)}>
                      <Image
                        src="textures/022/022.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Nubuck Royal Blue</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("23", 3)}>
                      <Image
                        src="textures/023/023.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Taupe Nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("24", 3)}>
                      <Image
                        src="textures/024/024.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Black nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("26", 3)}>
                      <Image
                        src="textures/026/026.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Brown Nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("28", 3)}>
                      <Image
                        src="textures/028/028.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Red Nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("77", 3)}>
                      <Image
                        src="textures/077/077.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Tan Nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("122", 3)}>
                      <Image
                        src="textures/122/122.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Twine nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("124", 3)}>
                      <Image
                        src="textures/124/124.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Indico Blue Nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("126", 3)}>
                      <Image
                        src="textures/126/126.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Blue Nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("240", 5)}>
                      <Image
                        src="textures/240/240.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Grey Nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("242", 3)}>
                      <Image
                        src="textures/242/242.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Fushia Nubuck</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("244", 3)}>
                      <Image
                        src="textures/244/244.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Purple Nubuck</span>
                    </span>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="tissus" title="Tissus">
                <Row>
                  <Col md={4}>
                    <span onClick={() => handleTexture("40", 2)}>
                      <Image
                        src="textures/040/040.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Ivory Satin</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("41", 2)}>
                      <Image
                        src="textures/041/041.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Black Satin</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("42", 2)}>
                      <Image
                        src="textures/042/042.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Fushia satin</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("43", 2)}>
                      <Image
                        src="textures/043/043.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Flesh satin</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("44", 2)}>
                      <Image
                        src="textures/044/044.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">White Deyable satin</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("46", 2)}>
                      <Image
                        src="textures/046/046.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Blue satin</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("47", 2)}>
                      <Image
                        src="textures/047/047.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Tan satin</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("48", 2)}>
                      <Image
                        src="textures/048/048.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Dark Tan Satin</span>
                    </span>
                  </Col>
                  <Col md={4}>
                    <span onClick={() => handleTexture("49", 2)}>
                      <Image
                        src="textures/049/049.png"
                        roundedCircle
                        width={"20%"}
                      />
                      <span className="textureName">Red Satin</span>
                    </span>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
}
